import React, {useEffect, useState} from 'react';
import Dropdown from '../../components/atoms/Dropdown';
import {TbLanguage} from 'react-icons/tb';
import Button from '../../components/atoms/Button';
import Footer from '../../components/organisms/Footer';
import Brand from '../../components/atoms/Brand';
import useFetchWebSetting from '../../hooks/useFetchWebSetting';
import {useWebSettingContext} from '../../context/WebSettingContext';

const HomePage = () => {
	const [language, setLanguage] = useState({
		label: 'EN',
		value: 'en',
	});
	const {
		webSetting,
	} = useWebSettingContext();

	const {
		onFetchWebSetting,
	} = useFetchWebSetting();

	const onLanguage = (option) => {
		setLanguage(option);
	};

	useEffect(() => {
		if (webSetting === null) {
			onFetchWebSetting();
		}
	}, []);

	return (
		<>
			<div className="landing-page-background">
				<div className="header container py-5">
					<div className="flex items-center justify-between">
						<Brand />
						<div className="language">
							<Dropdown
								icon={TbLanguage}
								defaultValue={language}
								onSelect={onLanguage}
								options={[
									{value: 'id', label: 'ID'},
									{value: 'en', label: 'EN'},
								]}
							/>
						</div>
					</div>
				</div>
				<div className="landing-information-frame container pt-10">
					<div
						className="
							flex
							items-start
							justify-between
							xl:flex-row
							flex-col-reverse
						"
					>
						<div className="information">
							<h1
								className='
								text-blue-dark
								font-[650]
								text-[40px]
								leading-tight
							'
							>
								Offset your carbon footprints and
								see what you can do to save the planet!
							</h1>
							<h4 className='text-blue-dark leading-normal font-medium py-8'>
								Lamun Carbon Calculator is a carbon offset and seagrass
								donation calculator developed by Lamun Warrior Foundation
								for education and preserving seagrass conservation in Indonesia.
							</h4>
							<div className="flex sm:flex-row flex-col gap-2">
								<Button
									label="Calculate Now"
									to={'/introduce'}
									variant='primary'
								/>
								<Button
									label="About Us"
									variant='none'
									to={
										webSetting?.landing_page_url ??
										process.env.REACT_APP_LANDING_PAGE_URL
									}
								/>
							</div>
							<p className="pt-10 pb-6 leading-loose text-sm text-blue-dark">
							Lamun Carbon Calculator is a carbon calculator designed
							and developed basis publicly available information and global
							carbon price recommendations and seagrass transplantation area
							requirements determined by seeds and TERFs methode.
							</p>
						</div>
						<img src="/assets/home-card-frame.png" width={800} alt="Lamun" />
					</div>
				</div>
				<Footer />
			</div>
		</>
	);
};

export default HomePage;
