/* eslint-disable max-len */

import React, {useEffect, useState} from 'react';
import Icon from './Icon';
import {FaAngleDown, FaAngleUp} from 'react-icons/fa';

const Dropdown = ({
	options, onSelect, defaultValue, icon, variant = 'primary',
}) => {
	const [selectedOption, setSelectedOption] = useState(null);
	const [isOpen, setIsOpen] = useState(false);

	const handleToggle = () => {
		setIsOpen(!isOpen);
	};

	const handleSelectOption = (option) => {
		setSelectedOption(option);
		onSelect(option);
		setIsOpen(false);
	};

	useEffect(() => {
		if (defaultValue) {
			const selected = options.filter((item) => {
				return item.value == defaultValue?.value;
			});

			if (selected.length > 0) setSelectedOption(selected[0]);
		}
	}, [defaultValue]);

	const btnVariantClass = {
		primary: `bg-blue-light text-white`,
		secondary: `
			bg-transparent
			border-grey
			border-2
			text-grey
			${isOpen ? '!bg-blue-dark-2 !text-white !border-blue-dark-2' : ''}
		`,
	};

	const ulVariantClass = {
		primary: 'border-blue-light text-blue-light',
		secondary: 'border-blue-dark-2 py-1 bg-transparent text-blue-dark-2',
	};

	const liVariantClass = {
		primary: 'border-blue-light text-blue-light hover:text-blue-dark',
		secondary: 'border-blue-dark-2 bg-white text-blue-dark-2 py-2 hover:font-bold',
	};

	return (
		<div className="dropdown relative">
			<button onClick={handleToggle} className={`
                flex
                gap-2
                items-center
                px-5
                py-2
                rounded
                w-full
                font-bold
				hover:cursor-pointer
				${btnVariantClass[variant]}
			`}>
				{icon ? <Icon name={icon} /> : null}
				{selectedOption ? selectedOption.label : 'Select an option'}
				{isOpen ? <FaAngleUp /> : <FaAngleDown />}
			</button>
			{isOpen && (
				<ul className={`
                    dropdown-menu
                    absolute
                    top-9
                    w-full
                    rounded-b-md
                    text-center
                    font-bold
					${ulVariantClass[variant]}
                `}>
					{options.map((option) => (
						<li
							className={`
                                w-full
                                border-2
                                p-1
                                border-t-0
                                transition
                                hover:cursor-pointer
								${liVariantClass[variant]}
							`}
							key={option.value}
							onClick={() => handleSelectOption(option)}
						>
							{option.label}
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default Dropdown;
