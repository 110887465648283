import React from 'react';
import {RouterProvider} from 'react-router-dom';
import router from './routes';
import Fallback from './components/organisms/Fallback';
import Provider from './providers';

const App = () => {
	return (
		<>
			<Provider>
				<RouterProvider router={router} fallbackElement={<Fallback />} />
			</Provider>
		</>
	);
};

export default App;
