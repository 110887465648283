/* eslint-disable max-len */
import React from 'react';
import usePopUp from '../../../hooks/usePopUp';
import {usePackageContext} from '../Context';
import currencyFormat from '../../../utilities/currency';
import separator from '../../../utilities/number';
import {toast} from 'react-toastify';
import {useNavigate, useParams} from 'react-router-dom';
import useStorePackage from './useStorePackage';

const useSumaryPopUp = () => {
	const navigate = useNavigate();
	const {token} = useParams();

	const {
		onPopUp,
	} = usePopUp();

	const {
		item,
		currency,
		currencyRate,
	} = usePackageContext();

	const {
		onStorePackage,
	} = useStorePackage();

	const SummaryPackageDetails = ({item, amount, seagrass}) => {
		return (
			<div className={`summary bg-blue-dark-2 bg-opacity-5 p-8 rounded-lg`}>
				<h4 className={`md:text-md text-md text-blue-dark font-semibold leading-loose mb-2`}>
                    Donation Package Details
				</h4>
				{item?.map((_item, key) => {
					if (_item?.qty > 0) {
						return (
							<p key={key} className='flex md:flex-row flex-col md:text-md text-sm items-center justify-between text-blue-dark-2 font-medium mb-2 leading-normal'>
								<span className="title text-left">{_item?.name} (x{_item?.qty})</span>
								<span className="amount text-end">{currencyFormat(_item?.amount, currency?.value)}</span>
							</p>
						);
					}
					return null;
				})}
				<div className="py-2 border-t-2 border-grey">
					<p className={`flex md:flex-row flex-col md:text-md text-sm items-center justify-between text-blue-dark font-bold`}>
                        Total Donation
						<span>
							{currencyFormat(amount, currency?.value)}
						</span>
					</p>
					<div className="text-center my-2 text-sm text-blue-light">
                        (Equal to {separator(seagrass)} m2 Seagrass Meadows)
					</div>
				</div>
			</div>
		);
	};

	const onSumaryPopUp = async () => {
		const amount = item?.reduce((total, _item) => {
			return (total || 0) + (_item?.amount*currencyRate || 0);
		}, 0);

		const seagrass = item?.reduce((total, _item) => {
			return (total || 0) + (_item?.total_seagrass || 0);
		}, 0);

		await onPopUp({
			title: 'Are you sure about your donation details?',
			size: 'md',
			confirmButtonText: 'Donor Now',
			html: <SummaryPackageDetails item={item} seagrass={seagrass} amount={amount} />,
			preConfirm: async () => {
				await onStorePackage()
					.then(() => {
						toast('Success to order packages', {
							type: 'success',
						});
						return navigate('/payment/' + token);
					})
					.catch((err) => {
						const {response} = err;

						if (response?.status === 404) {
							return navigate('/404');
						}

						toast(err?.message || 'Internal Server Error', {
							type: 'error',
						});
					});
			},
		}).then((res) => {
			if (res?.isConfirmed) {
				// return navigate('/payment/' + token);
			}
		}).catch((err) => {
			toast(err?.message || 'Internal Server Error', {
				type: 'error',
			});
			return navigate('/');
		});
	};

	return {
		onSumaryPopUp,
	};
};

export default useSumaryPopUp;
