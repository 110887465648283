import usePopUp from '../../../hooks/usePopUp';

const useQrisPaymentPopUp = () => {
	const {
		onPopUp,
	} = usePopUp();

	const onQrisPaymentPopUp = async () => {
		await onPopUp({
			title: 'Scan QR',
			size: 'md',
			html: `
                <img
					src='/assets/qris.png'
					class="m-auto my-6 sm:w-1/2 w-full rounded-md"
				/>
            `,
		});
	};

	return {
		onQrisPaymentPopUp,
	};
};

export default useQrisPaymentPopUp;
