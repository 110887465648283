/* eslint-disable max-len */

import usePopUp from '../../../hooks/usePopUp';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import useUpdatePayment from './useUpdatePayment';
import {useResultContext} from '../../Result/Context';

const useConfirmPaymentPopUp = () => {
	const navigate = useNavigate();

	const {
		setActivity,
	} = useResultContext();

	const {
		onPopUp,
	} = usePopUp();

	const {
		onUpdatePayment,
	} = useUpdatePayment();

	const onConfirmPaymentPopUp = async () => {
		await onPopUp({
			title: 'Are you sure you have completed the payment?',
			size: 'md',
			confirmButtonText: `Yes, I've finished it`,
			cancelButtonText: `No, I haven't finished it yet`,
			showCancelButton: true,
			preConfirm: async () => {
				await onUpdatePayment()
					.then(() => {
						onPopUp({
							title: 'Thank you and Congratulations!',
							html: `
                                <img src='/assets/finish.png' alt='image' class='w-3/4 m-auto'/>
                                <div class='text-blue-dark mt-4 leading-normal'>
                                    You have become a part of the Lamun Warrior members.
                                    Your money has been sent to help Lamun Warrior 
									<b>seagrass conservation sustainability, as</b> 
									your <b>responsibility</b> to your carbon footprint.
                                </div>  
                            `,
						});
					})
					.catch((err) => {
						const {response} = err;

						if (response?.status === 404) {
							return navigate('/404');
						}

						toast(err?.message || 'Internal Server Error', {
							type: 'error',
						});
					})
					.finally(() => {
						setActivity((prev) => ({
							...prev,
							is_payment: 1,
						}));
					});
			},
		}).then((res) => {
			if (res?.isConfirmed) {
				// return navigate('/payment/' + token);
			}
		}).catch((err) => {
			toast(err?.message || 'Internal Server Error', {
				type: 'error',
			});
			return navigate('/');
		});
	};

	return {
		onConfirmPaymentPopUp,
	};
};

export default useConfirmPaymentPopUp;
