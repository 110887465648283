import axios from 'axios';
import {useParams} from 'react-router-dom';

const useUpdatePayment = () => {
	const {token} = useParams();
	const url = process.env.REACT_APP_API_URL;

	const onUpdatePayment = async () => {
		const update = await axios.post(`${url}/donate/${token}/finish`);
		return update;
	};

	return {
		onUpdatePayment,
	};
};

export default useUpdatePayment;
