import axios from 'axios';

const useFetchPackage = () => {
	const url = process.env.REACT_APP_API_URL;

	const onFetchPackage = async () => {
		const packages = await axios.get(url + '/packages');
		return packages;
	};

	return {
		onFetchPackage,
	};
};

export default useFetchPackage;
