import React, {useContext} from 'react';
import {createContext, useState} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

const Context = createContext();

const schema = yup.object({
	name: yup.string().required().max(100),
	phone: yup.string().required().min(10).max(20),
	email: yup.string().email().required(),
	country: yup.object().required().typeError('Country is a required field'),
	state: yup.object().required().typeError('City is a required field'),
	city: yup.object().required().typeError('State is a required field'),
	carbon: yup.number().required().min(0),
	seagress: yup.number(),
	cost: yup.number(),
});

const IntroduceProvider = ({children}) => {
	const [country, setCountry] = useState(null);
	const [state, setState] = useState(null);
	const [city, setCity] = useState(null);
	const [countryLoading, setCountryLoading] = useState(false);
	const [stateLoading, setStateLoading] = useState(false);
	const [cityLoading, setCityLoading] = useState(false);

	const {
		setValue,
		handleSubmit,
		formState: {errors},
		getValues,
		register,
		setError,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			name: '',
			email: '',
			phone: '',
			country: '',
			state: '',
			city: '',
			carbon: 0,
		},
	});

	const values = {
		country,
		setCountry,
		handleSubmit,
		errors,
		state,
		register,
		setState,
		city,
		setCity,
		getValues,
		setValue,
		countryLoading,
		setCountryLoading,
		stateLoading,
		setStateLoading,
		cityLoading,
		setCityLoading,
		setError,
	};

	return (
		<Context.Provider value={values}>
			{children}
		</Context.Provider>
	);
};

export const useIntroduceContext = () => useContext(Context);
export default IntroduceProvider;
