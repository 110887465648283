import React from 'react';
import IntroduceProvider from '../pages/Introduce/Context';
import ResultProvider from '../pages/Result/Context';
import PackageProvider from '../pages/Package/Context';
import WebSettingProvider from '../context/WebSettingContext';

const contexts = [
	WebSettingProvider,
	IntroduceProvider,
	ResultProvider,
	PackageProvider,
];

const Provider = ({children}) => {
	return contexts.reduceRight((_children, Context) => {
		return <Context>{_children}</Context>;
	}, children);
};

export default Provider;
