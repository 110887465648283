/* eslint-disable max-len */

import React from 'react';
import Button from '../../atoms/Button';
import {FaHome} from 'react-icons/fa';
import Brand from '../../atoms/Brand';
import StepCounter from '../../molecules/StepCounter';

const Header = ({step = 1}) => {
	return (
		<div className="header container py-5">
			<div
				className="grid grid-cols-2 md:grid-cols-3 justify-between items-center"
			>
				<div className="md:col-start-1 md:col-end-2">
					<Brand />
				</div>

				<div className="relative md:col-start-2 col-span-3 md:col-end-3 mt-10 md:mt-0 md:order-2 order-3">
					<div
						className="line absolute top-5 md:top-auto bottom-5 w-full border-dashed border-t-4 border-gray-200"
					/>
					<div className="flex justify-center mt-2 gap-8">
						<StepCounter step={step} />
					</div>
				</div>

				<div className="flex justify-end md:col-start-3 md:col-end-4 md:order-3 order-2">
					<Button
						label={
							<span className="sm:block hidden">Back To Home</span>
						}
						icon={FaHome}
						to={'/'}
						variant='secondary-outline'
						className='
							!py-2
							!px-4
							sm:text-sm
							text-2xl
							w-fit
						'
					/>
				</div>
			</div>
		</div>

	);
};

export default Header;
