import React, {useState} from 'react';
import Button from '../../atoms/Button';
import {FaChevronCircleDown, FaChevronCircleUp} from 'react-icons/fa';
import {MdAdd, MdRemove} from 'react-icons/md';
import currencyFormat from '../../../utilities/currency';
import {usePackageContext} from '../../../pages/Package/Context';

const PackageCard = ({
	showDetail = false,
	title,
	price,
	info,
	description,
	image,
	defaultQty = 0,
	onChange = () => null,
}) => {
	const [openDetail, setOpenDetail] = useState(showDetail);
	const [qty, setQty] = useState(defaultQty || 0);

	const {currency} = usePackageContext();

	const onOpenSeeDetail = () => {
		return setOpenDetail(!openDetail);
	};

	const onAdd = () => {
		if (qty < 100) {
			const newQty = qty + 1;
			setQty(newQty);
			onChange(newQty);
		}
	};

	const onRemove = () => {
		if (qty > 0) {
			const newQty = qty - 1;
			setQty(newQty);
			onChange(newQty);
		}
	};

	return (
		<div className="my-4">
			<div className="
                summary
                bg-blue-dark-2
                bg-opacity-5
                p-8
                rounded-lg
            ">
				<div className="grid grid-cols-7 gap-5">
					<div className="md:col-span-3 col-span-7">
						<img
							className='rounded'
							src={image}
							alt="lamun"
							width={'100%'}
						/>
					</div>
					<div className="md:col-span-4 col-span-7">
						<h4 className='text-xl text-blue-dark font-semibold'>
							{title}
						</h4>
						<h2 className="text-3xl text-blue-dark font-bold my-2">
							{currencyFormat(price, currency?.value)}
						</h2>
						<p
							className="
                                text-sm
                                text-blue-dark
                                font-medium
                                leading-normal
                                my-4
                            "
						>
                            ({info})
						</p>
						<div
							className="
                                grid
                                grid-cols-7
                                items-center
                                justify-between
                                border-t-4
                                border-gray-lighter-2
                                py-4
                                md:w-3/4
                                w-full
                            "
						>
							<span
								className='
									flex
									sm:col-span-4
									col-span-3
									text-blue-dark
									font-medium
								'
							>
								Add
								<span className='sm:block hidden'>&#160;to Chart</span>:
							</span>
							<div className="col-span-1">
								<Button
									icon={MdRemove}
									className='!flex w-full !px-0 !py-2'
									onClick={onRemove}
								/>
							</div>
							<div className="sm:col-span-1 col-span-2">
								<div
									className='
                                        text-2xl
                                        font-bold
                                        text-blue-dark
                                        w-full
                                        text-center
                                    '
								>
									{qty}
								</div>
							</div>
							<div className="col-span-1">
								<Button
									className='!flex w-full !px-0 !py-2'
									icon={MdAdd}
									onClick={onAdd}
								/>
							</div>
						</div>
						<span
							className='
                                flex
                                gap-2
                                items-center
                                text-blue-light
                                hover:cursor-pointer
                            '
							onClick={onOpenSeeDetail}
						>See details {
								openDetail ? <FaChevronCircleUp/> : <FaChevronCircleDown />
							}</span>
					</div>
				</div>
				<article
					className={
						`
                            prose
                            transition-all
                            duration-75
                            overflow-hidden
                            ease-in-out
                            ${openDetail ? 'h-fit' : 'h-0'}
                        `
					}
				>
					<div
						className={
							`
                                prose
                                transition-opacity
                                duration-300
                                ease-in-out
                                mt-4
								!text-blue-dark
                                ${openDetail ? 'opacity-100' : 'opacity-0'}
                            `
						}
						dangerouslySetInnerHTML={{__html: description}}
					>
					</div>
				</article>
			</div>
		</div>
	);
};

export default PackageCard;
