/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/no-unescaped-entities */

import React, {useEffect, useState} from 'react';
import Layout from '../../layouts/Layout';
import Input from '../../components/atoms/Input';
import Button from '../../components/atoms/Button';
import {LuMoveLeft, LuMoveRight} from 'react-icons/lu';
import {useIntroduceContext} from '../Introduce/Context';
import {useNavigate, Link} from 'react-router-dom';
import {toFixed} from '../../utilities/number';

const CalculatePage = () => {
	const costPerKg = 961.5380;
	const seagressPerKg = 0.0625;
	const navigate = useNavigate();

	const {
		setValue,
		errors,
		handleSubmit,
		getValues,
	} = useIntroduceContext();

	const [form, setForm] = useState({
		carbon: getValues('carbon'),
		seagress: getValues('carbon')*seagressPerKg,
		cost: getValues('carbon')*costPerKg,
	});

	const onCalculate = (e) => {
		let val = parseFloat(e.target.value);

		if (val === NaN || val === '') {
			val = 0;
		}

		setForm(() => ({
			carbon: val,
			seagress: val*seagressPerKg,
			cost: val*costPerKg,
		}));
	};

	const onBlur = () => {
		if (form?.carbon === '' || isNaN(form?.carbon)) {
			setForm(() => ({
				carbon: 0,
				seagress: 0,
				cost: 0,
			}));
		}
	};

	const onSubmit = (e) => {
		return navigate('/loading');
	};

	const onReset = (e) => {
		setForm({
			carbon: 0,
			seagress: 0,
			cost: 0,
		});
	};

	useEffect(() => {
		if (!getValues('name')) navigate('/introduce');
	}, []);

	useEffect(() => {
		setValue('carbon', form?.carbon);
	}, [form]);

	return (
		<>
			<Layout step={2}>
				<div className="container md:w-3/6 w-full m-auto">
					<div className="introduce text-center !mb-6">
						<h4 className='text-blue-dark text-xl font-semibold'>
							<Link target='_blank' to={`https://www.carbonethics.co/carbon-calculator?utm_source=SocialMedia&utm_id=CarbonEthics&utm_content=LamunWarrior&utm_campaign=AlwaysOn&utm_medium=Instagram&utm_term=Partnership`} className='font-bold !underline'>Click HERE</Link>,
							for know your carbon footprint
						</h4>
						<p className='text-blue-dark text-sm'>
							Calculate your Carbon Offset with Seagrass
						</p>
					</div>
					<form onSubmit={handleSubmit(onSubmit)} className='space-y-6'>
						<Input
							name='carbon'
							type='number'
							label='Input your carbon offset (kg/CO2e)'
							placeholder='Enter carbon offset...'
							onChange={onCalculate}
							onBlur={onBlur}
							error={errors?.name?.message}
							value={form?.carbon}
						/>

						<div className="grid grid-cols-2 gap-6">
							<Input
								disabled
								name='donation'
								type='number'
								placeholder='(Donation needed will shown here)'
								value={toFixed(form?.seagress)}
							/>

							<Input
								disabled
								name='nominal'
								type='number'
								placeholder='Rupiah (Rp)'
								value={toFixed(form?.cost)}
							/>
						</div>

						<Button
							type='reset'
							label={'Recalculate'}
							className='px-16 py-1'
							onClick={onReset}
						/>

						<div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-10 gap-4">
							<Button
								to='/introduce'
								label={'Previous'}
								icon={LuMoveLeft}
								className='m-auto px-16 py-2 w-full'
								variant='secondary'
							/>
							<Button
								type='submit'
								label={'Next'}
								icon={LuMoveRight}
								iconPostition='right'
								className='m-auto px-16 py-2 w-full'
							/>
						</div>
					</form>
					<p className='my-20 text-center text-blue-dark'>
						Lamun Carbon Calculator is a carbon calculator designed
						and developed basis publicly available information and global
						carbon price recommendations and seagrass transplantation
						area requirements determined by TERFs.
					</p>
				</div>
			</Layout>
		</>
	);
};

export default CalculatePage;
