import React, {useEffect} from 'react';
import Background from '../components/molecules/Background';
import Header from '../components/organisms/Header';
import Footer from '../components/organisms/Footer';
import {ToastContainer} from 'react-toastify';
import useFetchWebSetting from '../hooks/useFetchWebSetting';
import {useWebSettingContext} from '../context/WebSettingContext';

const Layout = ({
	step = 1,
	children,
}) => {
	const {
		webSetting,
	} = useWebSettingContext();

	const {
		onFetchWebSetting,
	} = useFetchWebSetting();

	useEffect(() => {
		if (webSetting === null) {
			onFetchWebSetting();
		}
	}, []);

	return (
		<>
			<div className="min-h-screen relative">
				<ToastContainer />
				<Background />
				<Header
					step={step}
				/>
				{children}
				<Footer />
			</div>
		</>
	);
};

export default Layout;
