/* eslint-disable max-len */
import React, {useState} from 'react';
import Button from '../../atoms/Button';
import {MdOutlineArrowDropDownCircle} from 'react-icons/md';
import {useWebSettingContext} from '../../../context/WebSettingContext';
import useQrisPaymentPopUp from '../../../pages/Payment/hooks/useQrisPaymentPopUp';

const PaymentMethodCard = () => {
	const [isOpenBankStep, setIsOpenBankStep] = useState(false);
	const [isOpenWalletStep, setIsOpenWalletStep] = useState(false);

	const {
		webSetting,
	} = useWebSettingContext();

	const {
		onQrisPaymentPopUp,
	} = useQrisPaymentPopUp();

	const onOpenBankStep = () => {
		setIsOpenBankStep((prev) => !prev);
	};

	const onOpenWalletStep = () => {
		setIsOpenWalletStep((prev) => !prev);
	};

	return (
		<div className="payment-method my-8 space-y-4">
			<h4 className="text-xl font-medium text-blue-dark">
                Choose the Donation Method:
			</h4>
			<div className="md:flex block justify-start items-start gap-4">
				{
					webSetting ? (
						<>
							<div className="flex gap-4">
								<div className="image">
									<img
										src="/assets/payment-frame-bank.png"
										alt="Payment Method"
										width={125}
									/>
								</div>
								<Button
									className={`
										bg-transparent
										!text-blue-dark
										!p-0
										text-4xl
										hover:!bg-transparent
										hover:!text-blue-dark
										${isOpenBankStep ? 'rotate-180' : ''}
										transition-all
									`}
									icon={MdOutlineArrowDropDownCircle}
									onClick={onOpenBankStep}
								/>
							</div>
							<div className="method-description md:mt-0 mt-4">
								<div
									className={`transition-all ${isOpenBankStep ? 'block' : 'hidden'} prose description text-blue-dark`}
									dangerouslySetInnerHTML={{__html: webSetting?.payment_method}}
								/>
							</div>
						</>
					): (
						<>
							<div className="w-full rounded h-28 bg-gray-200 animate-pulse" />
							<div className="w-full rounded h-28 bg-gray-200 animate-pulse" />
						</>
					)
				}
			</div>
			<div className="md:flex block justify-start items-start gap-4">
				{
					webSetting ? (
						<>
							<div className="flex gap-4">
								<div className="image">
									<img
										src="/assets/payment-frame-ewallet.png"
										alt="Payment Method"
										width={125}
									/>
								</div>
								<Button
									className={`
										bg-transparent
										!text-blue-dark
										!p-0
										text-4xl
										hover:!bg-transparent
										hover:!text-blue-dark
										${isOpenWalletStep ? 'rotate-180' : ''}
										transition-all
									`}
									icon={MdOutlineArrowDropDownCircle}
									onClick={onOpenWalletStep}
								/>
							</div>
							<div className="method-description md:mt-0 mt-4">
								<div
									className={`transition-all ${isOpenWalletStep ? 'block' : 'hidden'} prose description text-blue-dark`}
									dangerouslySetInnerHTML={{__html: webSetting?.payment_method_ewallet}}
								/>
							</div>
						</>
					): (
						<>
							<div className="w-full rounded h-28 bg-gray-200 animate-pulse" />
							<div className="w-full rounded h-28 bg-gray-200 animate-pulse" />
						</>
					)
				}
			</div>
			<div className="md:flex block justify-between gap-4">
				{
					webSetting ? (
						<>
							<div className="flex items-center gap-4">
								<div className="image">
									<img
										src="/assets/payment-frame-qris.png"
										alt="Payment Method"
										width={125}
									/>
								</div>
								<Button
									className={`
										bg-transparent
										!text-blue-dark
										!p-0
										text-4xl
										hover:!bg-transparent
										hover:!text-blue-dark
										transition-all
									`}
									icon={MdOutlineArrowDropDownCircle}
									onClick={onQrisPaymentPopUp}
								/>
							</div>
							<div className="method-description md:mt-0 mt-4"></div>
						</>
					): (
						<>
							<div className="w-full rounded h-28 bg-gray-200 animate-pulse" />
							<div className="w-full rounded h-28 bg-gray-200 animate-pulse" />
						</>
					)
				}
			</div>
		</div>
	);
};

export default PaymentMethodCard;
