import React from 'react';
import {useResultContext} from '../../../pages/Result/Context';
import separator from '../../../utilities/number';
import currency from '../../../utilities/currency';
import moment from 'moment/moment';

const CarbonCard = ({
	className = '',
}) => {
	const {activity} = useResultContext();
	const onDownload = () => {
		const base64Data = activity?.card;
		const byteCharacters = atob(base64Data);
		const byteNumbers = new Array(byteCharacters.length);

		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], {type: 'application/pdf'});
		const blobUrl = URL.createObjectURL(blob);
		const fileName = `${activity?.token}.pdf`;

		const downloadLink = document.createElement('a');
		downloadLink.href = blobUrl;
		downloadLink.download = fileName;

		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);

		URL.revokeObjectURL(blobUrl);
	};

	return (
		<div className={`col-span-2 ${className}`}>
			<div className="relative">
				<img
					src="/assets/evidence-card-frame.png"
					className='md:w-full w-full'
					alt="Card"
				/>
				<div
					style={{
						fontSize: '6px',
						left: '7%',
						top: '10%',
					}}
					className="
						carbon
						absolute
						text-blue-dark-2
						font-bold
					"
				>
					{moment(activity?.created_at).format('DD MMMM YYYY')}
				</div>
				<div
					style={{
						fontSize: '8px',
						left: '50%',
						top: '43%',
					}}
					className="
						carbon
						absolute
						text-blue-dark-2
						font-bold
					"
				>
					{separator(activity?.carbon)} kgCO2e/m2/year
				</div>
				<div
					style={{
						fontSize: '6px',
						left: '50%',
						top: '48%',
					}}
					className="
						carbon
						absolute
						text-blue-dark-2
						font-bold
					"
				>
					(Equivalent to {currency(activity?.amount)})
				</div>
				<div
					style={{
						fontSize: '8px',
						left: '50%',
						top: '57%',
					}}
					className="
						carbon
						absolute
						text-blue-dark-2
						font-bold
					"
				>
					{separator(activity?.seagress)} m²
				</div>
			</div>
			<div className="my-5">
				<p className='text-grey text-center'>
                        Your carbon card will automatically be downloaded.
                        If your download does not start,
					<span
						className='font-bold text-blue-dark hover:cursor-pointer'
						onClick={onDownload}
					> click here</span>
				</p>
			</div>
		</div>
	);
};

export default CarbonCard;
