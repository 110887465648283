import React, {useEffect} from 'react';
import {useIntroduceContext} from '../Introduce/Context';
import {useNavigate} from 'react-router-dom';
import useStoreCalculate from './hooks/useStoreCalculate';
import Layout from '../../layouts/Layout';
import useFetchWebSetting from '../../hooks/useFetchWebSetting';
import {useWebSettingContext} from '../../context/WebSettingContext';

const LoadingPage = () => {
	const navigate = useNavigate();

	const {
		onStoreCalculate,
	} = useStoreCalculate();

	const {
		getValues,
	} = useIntroduceContext();

	const {
		onFetchWebSetting,
	} = useFetchWebSetting();

	const {
		webSetting,
	} = useWebSettingContext();

	const {name, email, carbon} = getValues();

	useEffect(() => {
		if (!name && !email && !carbon) {
			return navigate('/');
		}

		if (webSetting === null) {
			onFetchWebSetting();
		}

		onStoreCalculate();
	}, []);

	return (
		<Layout step={3}>
			<div className="container xl:w-4/6 w-full m-auto min-h-full">
				<img
					src="/assets/loading.gif"
					width={475}
					alt="Loading"
					className='m-auto'
				/>
			</div>
		</Layout>
	);
};

export default LoadingPage;
