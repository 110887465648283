import React from 'react';
import Step from '../../atoms/Step';

const StepCounter = ({step}) => {
	return (
		<>
			<Step
				label={1}
				isActive={step === 1}
			/>
			<Step
				label={2}
				isActive={step === 2}
			/>
			<Step
				label={3}
				isActive={step === 3}
			/>
			<Step
				label={4}
				isActive={step === 4}
			/>
			<Step
				label={5}
				isActive={step === 5}
			/>
		</>
	);
};

export default StepCounter;
