import React from 'react';

const Input = ({
	type = 'text',
	name,
	onChange,
	onBlur = undefined,
	value = undefined,
	error = null,
	label = null,
	className = '',
	placeholder = 'Enter',
	disabled = false,
	ref = null,
}) => {
	return (
		<div className='form-group'>
			<label
				htmlFor={name}
				className='capitalize text-blue-dark font-medium'
			>
				{label}
			</label>
			<input
				disabled={disabled}
				type={type}
				name={name}
				id={name}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				className={`
					mt-2
                    border-l-gray-light
                    border-2
                    rounded-md
                    py-3
                    px-3
					w-full
                    ${className}
                `}
				placeholder={placeholder}
				ref={ref}
			/>
			{
				error ? (
					<span className='text-red-500 text-sm'>{error}</span>
				) : (null)
			}
		</div>
	);
};

export default Input;
