import React from 'react';
import ReactSelect from 'react-select';

const Select = ({
	name,
	onChange,
	value,
	error = null,
	label = null,
	placeholder = 'Enter',
	options = [],
	disabled = false,
	onMenuOpen = null,
	onMenuClose = null,
	isLoading = false,
}) => {
	const styles = {
		control: (provided, state) => ({
			...provided,
			'borderColor': state.isFocused ? '#000000' : '#C3CFD8',
			'borderLeftColor': '#C3CFD8',
			'borderWidth': '1px',
			'borderRadius': '0.375rem',
			'paddingTop': '0.50rem',
			'paddingBottom': '0.50rem',
			'paddingLeft': '0.50rem',
			'paddingRight': '0.50rem',
			'width': '100%',
			'outline': state.isFocused ?
				'1px solid #000000' : '1px solid transparent',
			'&:hover': {
				borderColor: '#000000',
			},
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isSelected ? '#EBF8FF' : '#FFFFFF',
			color: state.isSelected ? '#276678' : '#000000',
		}),
	};

	return (
		<div className='form-group'>
			<label
				htmlFor={name}
				className='capitalize text-blue-dark font-medium'
			>
				{label}
			</label>
			<ReactSelect
				placeholder={placeholder}
				options={options}
				isDisabled={disabled}
				styles={styles}
				className='mt-2'
				isLoading={isLoading}
				onMenuOpen={onMenuOpen}
				onChange={onChange}
				value={value}
			/>
			{
				error ? (
					<span className='text-red-500 text-sm'>{error}</span>
				) : (null)
			}
		</div>
	);
};

export default Select;
