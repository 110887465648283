/* eslint-disable max-len */
/* eslint-disable new-cap */

import React, {useEffect, useState} from 'react';
import Layout from '../../layouts/Layout';
import Button from '../../components/atoms/Button';
import {useNavigate, useParams} from 'react-router-dom';
import Subscribe from '../../components/organisms/Subscribe';
import {useResultContext} from './Context';
import useFetchResult from './hooks/useFetchResult';
import {toast} from 'react-toastify';
import ErrorPage from '../ErrorPage';
import currency from '../../utilities/currency';
import separator from '../../utilities/number';
import CarbonCard from '../../components/molecules/CarbonCard';
import usePopUpBlueCarbon from './hooks/usePopUpBlueCarbon';

const IntroducePage = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const {
		onPopUpBlueCarbon,
	} = usePopUpBlueCarbon();

	const {token} = useParams();
	const navigate = useNavigate();

	const {
		activity,
		setActivity,
	} = useResultContext();

	const {
		onFetchResult,
	} = useFetchResult();

	useEffect(() => {
		if (activity) {
			setLoading(false);
		} else {
			setLoading(true);
			onFetchResult({
				token,
			})
				.then((res) => {
					const {data} = res;
					const {result} = data;
					setActivity(result);
				})
				.catch((err) => {
					const {response} = err;
					if (response?.status === 404) {
						return setError({
							status: 404,
						});
					}
					toast(
						response?.data?.message || err?.message || 'Internal Error Server',
						{
							type: 'error',
						},
					);
					return navigate('/');
				})
				.finally(() => {
					return setLoading(false);
				});
		}
	}, []);

	if (loading) {
		return (
			<Layout step={3}>
				<div className="container xl:w-4/6 w-full m-auto min-h-full">
					<img
						src="/assets/loading.gif"
						width={475}
						alt="Loading"
						className='m-auto'
					/>
				</div>
			</Layout>
		);
	}

	return (
		<>
			{error ? (
				<ErrorPage status={error?.status} />
			) : (
				<Layout step={4}>
					<div className="container xl:w-4/6 w-full m-auto">
						<div className="md:grid block grid-cols-5 gap-10">
							<div className="col-span-3">
								<p className='text-sm text-grey leading-10'>
									Oops, your activities harms the planet!
								</p>
								<h4 className='text-lg font-semibold text-blue-dark leading-10'>
									Your carbon footprint:
								</h4>
								<h2 className='text-3xl font-bold text-blue-dark leading-10'>
									{separator(activity?.carbon)} kgCO2e/m²/year
								</h2>
								<h4 className='text-xl text-blue-dark leading-loose my-5'>
									(Equivalent to {currency(activity?.amount)})
								</h4>
								<h4 className='
									text-xl font-semibold leading-normal text-blue-dark
								'>
									Total seagrass meadow needed to absorb your carbon
									footprint:
								</h4>
								<h2 className='text-3xl font-bold text-blue-dark leading-10'>
									{separator(activity?.seagress)} m²
								</h2>

								<CarbonCard className='md:hidden block mt-8' />

								<p className='text-blue-dark leading-relaxed my-4'>
									You can offset for your carbon footprint
									by seagrass meadow restoration.
									Start from Rp25.000/bag of seagrass package,you make a
									change to reduce carbon emissions on earth.
								</p>
								<p className='text-blue-dark leading-relaxed my-4'>
									A package of seagrass seeds could save thousands
									of lives. Offset your carbon now and join
									our movement!
								</p>

								<div
									className="
										grid
										md:grid-cols-2
										grid-cols-1
										md:gap-10
										gap-4
										my-10
									"
								>
									<Button
										variant='primary'
										label={'Donor Now'}
										to={`/package/${token}`}
									/>
									<Button
										variant='primary-outline'
										label={'About Blue Carbon'}
										onClick={onPopUpBlueCarbon}
									/>
								</div>
							</div>
							<CarbonCard className='md:block hidden' />
						</div>
						<p className="text-medium text-grey font-medium">Did you know?</p>
						<p className="text-grey leading-relaxed">
							Seagrass meadows could accumulate and store organic
							carbon enables seagrass habitat to provide to 15%
							of the total carbon deposited in the ocean.
							With their significant ability to absorb and store
							carbon, seagrass habitats can substantially contribute
							to climate change mitigation. (SCC LIPI, 2020)
						</p>
						<Subscribe />
					</div>
				</Layout>
			)}
		</>
	);
};

export default IntroducePage;
