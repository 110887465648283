import React from 'react';
import Background from '../../components/molecules/Background';
import {useRouteError} from 'react-router-dom';

const ErrorPage = ({
	status,
	title,
	message = `
		Oops! It seems you've stumbled upon uncharted territory.
		Our servers couldn't find the page you're looking for.
		But fear not! Navigate back to safety or explore our
		other digital realms.
		Let's journey together to find what you seek!
	`,
}) => {
	const error = useRouteError();
	return (
		<>
			<Background
				classNameBottom={'!bottom-0'}
			/>
			<div className="min-h-screen flex items-center justify-center">
				<div className="page text-center">
					<h1
						className='text-9xl font-bold text-blue-dark leading-tight'
					>
						{status || error?.status || 500}
					</h1>
					<h4 className='text-4xl font-medium text-blue-dark leading-relaxed'>
						{
							(status || error?.status) == '404' ?
								(title || 'Oops! Page Not Found') :
								(title || error?.message || 'Internal Server Error')
						}
					</h4>
					<p className='mt-5 leading-relaxed px-20 sm:w-3/4 w-full m-auto'>
						{
							message
						}
					</p>
				</div>
			</div>
		</>
	);
};

export default ErrorPage;
