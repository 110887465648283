import axios from 'axios';
import {useWebSettingContext} from '../context/WebSettingContext';
import {toast} from 'react-toastify';

const useFetchWebSetting = () => {
	const {
		setWebSetting,
	} = useWebSettingContext();

	const url = process.env.REACT_APP_API_URL;

	const onFetchWebSetting = async () => {
		const result = await axios.get(`${url}/web-setting`)
			.then((res) => {
				return setWebSetting(res?.data?.result);
			})
			.catch((err) => {
				return toast('Oops, please check connection or contact us.', {
					type: 'error',
				});
			});

		return result;
	};

	return {
		onFetchWebSetting,
	};
};

export default useFetchWebSetting;
