import React from 'react';

const Background = ({
	classNameBottom = '',
}) => {
	return (
		<>
			<div className={`bg-bottom ${classNameBottom}`} />
			<div className="bg-start" />
			<div className="bg-end" />
		</>
	);
};

export default Background;
