import axios from 'axios';
import {useIntroduceContext} from '../Context';
import {toast} from 'react-toastify';

const useFetchCountry = () => {
	const {
		country,
		setCountry,
		state,
		setState,
		city,
		setCity,
		data,
		setCountryLoading,
		setStateLoading,
		setCityLoading,
	} = useIntroduceContext();

	const url = process.env.REACT_APP_API_URL;

	const onFetchCountry = async () => {
		if (country !== null) return;
		setCountryLoading(true);
		await axios.get(`${url}/countries`)
			.then((res) => {
				setCountry(res.data.result);
				return {
					err: null,
					data: res.data,
				};
			})
			.catch((err) => {
				return toast(err.message || '500 - Internal Server Error', {
					type: 'error',
				});
			})
			.finally(() => {
				setCountryLoading(false);
			});
	};

	const onFetchState = async (country) => {
		if (state !== null) return;
		setStateLoading(true);
		await axios.get(`${url}/countries/${country?.value || data.country}/states`)
			.then((res) => {
				setState(res.data.result);
				return {
					err: null,
					data: res.data,
				};
			})
			.catch((err) => {
				return toast(err.message || '500 - Internal Server Error', {
					type: 'error',
				});
			})
			.finally(() => {
				setStateLoading(false);
			});
	};

	const onFetchCity = async (country = null, state = null) => {
		if (city !== null) return;
		setCityLoading(true);
		await axios.get(`
			${url}/countries/
			${country?.value || data.country}/states/
			${state?.value || data.state}/cities
		`)
			.then((res) => {
				setCity(res.data.result);
				return {
					err: null,
					data: res.data,
				};
			})
			.catch((err) => {
				return toast(err.message || '500 - Internal Server Error', {
					type: 'error',
				});
			})
			.finally(() => {
				setCityLoading(false);
			});
	};

	return {
		onFetchCountry,
		onFetchState,
		onFetchCity,
	};
};

export default useFetchCountry;
