import React from 'react';
import Dropdown from '../../atoms/Dropdown';
import {usePackageContext} from '../../../pages/Package/Context';

const CurrencyDropdown = () => {
	const {
		currency,
		setCurrency,
	} = usePackageContext();

	const onSelectCurrency = (value) => {
		setCurrency(value);
	};

	return (
		<Dropdown
			variant='secondary'
			defaultValue={currency}
			onSelect={onSelectCurrency}
			options={[
				{
					label: 'Rupiah (Rp)',
					value: 'idr',
				},
				// {
				// 	label: 'Yuan (CN¥)',
				// 	value: 'cny',
				// },
				// {
				// 	label: 'Yen (JPY)',
				// 	value: 'jpy',
				// },
				// {
				// 	label: 'Riyal (﷼)',
				// 	value: 'sar',
				// },
				// {
				// 	label: 'Won (₩)',
				// 	value: 'krw',
				// },
				// {
				// 	label: 'Pound (£)',
				// 	value: 'gbp',
				// },
				// {
				// 	label: 'Dollar ($)',
				// 	value: 'usd',
				// },
			]}
		/>
	);
};

export default CurrencyDropdown;
