/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import Layout from '../../layouts/Layout';
import CurrencyDropdown from '../../components/molecules/CurrencyDropdown';
import PackageCard from '../../components/molecules/PackageCard';
import ErrorPage from '../ErrorPage';
import useFetchPackage from './hooks/useFetchPackage';
import {usePackageContext} from './Context';
import {toast} from 'react-toastify';
import {useNavigate, useParams} from 'react-router-dom';
import Button from '../../components/atoms/Button';
import {FaShoppingCart} from 'react-icons/fa';
import SummaryPackageCard from '../../components/molecules/SummaryPackageCard';
import useSumaryPopUp from './hooks/useSumaryPopUp';
import useFetchResult from '../Result/hooks/useFetchResult';
import Subscribe from '../../components/organisms/Subscribe';

const Package = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingActivity, setIsLoadingActivity] = useState(true);

	const [error, setError] = useState(false);

	const navigate = useNavigate();

	const {token} = useParams();

	const {
		onFetchPackage,
	} = useFetchPackage();

	const {
		onFetchResult,
	} = useFetchResult();

	const {
		onSumaryPopUp,
	} = useSumaryPopUp();

	const {
		packages,
		setPackages,
		item,
		onChangeItem,
		currencyRate,
	} = usePackageContext();

	const amount = item?.reduce((total, _item) => {
		return (total || 0) + (_item?.amount || 0);
	}, 0);

	useEffect(() => {
		onFetchPackage()
			.then((res) => {
				const {data} = res;
				const {result} = data;
				setPackages(result);
			})
			.catch((err) => {
				const {response} = err;
				if (response?.status === 404) {
					return setError({
						status: 404,
					});
				}
				toast(
					response?.data?.message || err?.message || 'Internal Error Server',
					{
						type: 'error',
					},
				);
				return navigate('/');
			})
			.finally(() => {
				return setIsLoading(false);
			});
	}, []);

	useEffect(() => {
		onFetchResult({
			token,
		})
			.then((res) => {
				const {data} = res;
				const {result} = data;

				if (result?.packages?.length > 0) {
					return navigate('/payment/' + token);
				}
			})
			.catch((err) => {
				const {response} = err;
				if (response?.status === 404) {
					return navigate('/404');
				}
				toast(
					response?.data?.message || err?.message || 'Internal Error Server',
					{
						type: 'error',
					},
				);
				return navigate('/');
			})
			.finally(() => {
				return setIsLoadingActivity(false);
			});
	});

	return (
		<>
			{error ? (
				<ErrorPage status={error?.status} />
			) : (
				<Layout step={5}>
					<div className="container xl:w-5/6 w-full m-auto min-h-screen">
						{(isLoading || isLoadingActivity) ? (
							<div className="animate-pulse grid grid-cols-6 gap-8">
								<div className="md:col-span-4 col-span-6">
									{Array.from({length: 6}, (_, index) => (
										<div key={index} className="grid grid-cols-7 gap-8 p-10 bg-gray-200 rounded-lg h-80 mb-4">
											<div className="bg-gray-300 md:col-span-3 col-span-7 rounded-xl"></div>
											<div className="bg-gray-200 md:col-span-4 col-span-7">
												<div className="bg-gray-300 h-6 xl:w-32 w-2/6 mb-4"></div>
												<div className="bg-gray-300 h-6 xl:w-60 w-4/6 mb-4"></div>
												<div className="bg-gray-300 h-6 xl:w-72 w-5/6 mb-4"></div>
												<div className="bg-gray-300 h-12 xl:w-60 w-4/6 mb-4"></div>
												<div className="bg-gray-300 h-6 xl:w-32 w-2/6 mb-4"></div>
											</div>
										</div>
									))}
								</div>
								<div className="col-span-2 bg-gray-200 rounded-lg h-40 md:block hidden "></div>
							</div>
						) : (
							<div className="grid grid-cols-6 gap-8">
								<div className="md:col-span-4 col-span-6">
									<div className="container-header flex sm:justify-between justify-end items-center">
										<h4 className='text-xl text-blue-dark font-semibold sm:block hidden'>
                                            Choose the Donation Package:
										</h4>
										<div className="flex gap-4 items-center">
											<p className="font-semibold text-blue-dark">
                                                Currency:
											</p>
											<CurrencyDropdown />
										</div>
									</div>
									{
										packages?.map((_package, key) => {
											const currentQty = item?.find((_item) => {
												return _item?.id === _package?.id;
											})?.qty;

											return (
												<div key={key}>
													<PackageCard
														showDetail={key===0}
														image={_package?.thumbnail}
														title={_package?.title}
														price={(_package?.price)*Number(currencyRate)}
														info={_package?.short_description}
														description={_package?.description}
														onChange={(qty) => onChangeItem(_package?.id, qty)}
														defaultQty={currentQty}
													/>
												</div>
											);
										})
									}
								</div>
								<div className="col-span-2 md:block hidden">
									<SummaryPackageCard />
								</div>
								<div className="mobile-btn fixed bottom-4 right-4">
									<Button
										className='!rounded-full !px-4 text-2xl md:hidden block'
										icon={FaShoppingCart}
										onClick={onSumaryPopUp}
										disabled={amount === 0}
									/>
								</div>
							</div>
						)}
						<Subscribe />
					</div>
				</Layout>
			)}
		</>
	);
};

export default Package;
