import React from 'react';

const Icon = ({name, size, color}) => {
	const IconComponent = name;
	return (
		<IconComponent size={size} color={color}/>
	);
};

export default Icon;
