import React, {useState} from 'react';
import Icon from '../../atoms/Icon';
import Button from '../../atoms/Button';
import {MdEmail} from 'react-icons/md';
import useStoreNewslatter from '../../../hooks/useStoreNewslatter';
import usePopUp from '../../../hooks/usePopUp';

const Subscribe = () => {
	const [email, setEmail] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const {onStoreNewslatter} = useStoreNewslatter();
	const {
		onPopUp,
	} = usePopUp();

	const onSubscribe = () => {
		setIsLoading(true);
		onStoreNewslatter({email})
			.then((res) => {
				return onPopUp({
					size: 'sm',
					title: 'Success',
					text: 'Unlock Your Potential. Subscribe Today!',
				});
			})
			.catch((err) => {
				return onPopUp({
					size: 'sm',
					icon: 'error',
					title: err?.response?.statusText ||
						'Internal Error Server',
					text: err?.response?.data?.message ||
						'Please contact administrator',
				});
			})
			.finally(() => {
				setIsLoading(false);
				setEmail(null);
				return;
			});
	};

	return (
		<div
			className="
                subscribe
                border-t-4
                border-dashed
                border-grey
                my-10
                pt-12
            "
		>
			<p className='font-medium text-center text-blue-dark md:px-28 px-0'>
                Want to learn more about seagrass conservation, coastal
                ecosystems, and youth activities? Let`s subscribe to
                always get the latest article info from Lamunpedia. Free!
			</p>
			<div
				className="
                    flex
                    m-auto
                    items-center
                    border-grey
                    border-2
                    rounded-xl
                    my-10
                    md:w-1/2
					w-full
					overflow-hidden
                "
			>
				<div className="icon text-blue-dark px-6 py-2 md:block hidden">
					<Icon
						name={MdEmail}
						size='40'
					/>
				</div>
				<div className="form w-full">
					<input
						type="text"
						className='w-full outline-none md:h-14 md:px-0 px-2'
						placeholder='Enter your email address'
						onChange={(e) => setEmail(e.target.value)}
						value={email}
					/>
				</div>
				<Button
					type='submit'
					variant='primary'
					label={'Subscribe'}
					className='rounded-s-none'
					onClick={onSubscribe}
					loading={isLoading}
				/>
			</div>
			<p className="text-center text-grey">
                Lamun Carbon Calculator is a carbon calculator
                designed and developed basis publicly available
                information and scientific recommendations from
                the European Environment Agency (EEA) and seagrass
                transplantation area requirements determined by seeds
                and TERFs methode.
			</p>
		</div>
	);
};

export default Subscribe;
