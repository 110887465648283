/* eslint-disable max-len */

import React from 'react';
import Icon from './Icon';
import {Link} from 'react-router-dom';

const Button = ({
	label,
	icon,
	iconPostition = 'left',
	type = 'submit',
	to = null,
	onClick = null,
	variant = 'primary',
	className = '',
	disabled = false,
	loading = false,
}) => {
	const classNameVariant = {
		'none': 'text-blue-light',
		'primary': 'bg-blue-light text-white hover:bg-blue-dark',
		'secondary': 'bg-gray-light text-white hover:bg-gray-300',
		'primary-outline': 'border-2 border-blue-light text-blue-light hover:bg-blue-light hover:text-white',
		'secondary-outline': 'border-2 border-gray-light text-gray-light hover:bg-gray-light hover:text-white',
	};

	if (disabled) {
		variant = 'secondary';
	}

	const classNameVariantSelected = classNameVariant[variant];

	return (
		!to ? (
			<button
				type={type}
				onClick={onClick}
				className={`
					flex
					gap-2
					items-center
					rounded-md
					font-medium
					justify-center
					px-8
					py-4
					transition
					${classNameVariantSelected}
					${className}
					${iconPostition === 'left' ? '' : 'flex-row-reverse'}
				`}
				disabled={loading || disabled}
			>
				{icon ? <Icon name={icon} /> : null}
				{
					loading ?
						<div className="border-gray-300 h-6 w-6 animate-spin rounded-full border-4 border-t-grey" /> :
						label
				}
			</button>
		) : (
			<Link
				to={to}
				className={`
					flex
					gap-2
					items-center
					justify-center
					rounded-md
					font-medium
					px-8
					py-4
					transition
					!text-center
					${classNameVariantSelected}
					${className}
				`}
			>
				{icon ? <Icon name={icon} /> : null}
				{label}
			</Link>
		)
	);
};

export default Button;
