/* eslint-disable max-len */

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const usePopUp = () => {
	const onPopUp = ({
		icon,
		title = '',
		text = null,
		html = null,
		size = 'md',
		confirmButtonText = null,
		preConfirm = null,
		cancelButtonText = 'Cancel',
		showCancelButton = false,
	}) => {
		const variantSize = {
			'sm': 'xl:w-1/4 md:w-2/5 sm:w-3/5 w-full',
			'md': 'xl:w-2/4 md:w-3/5 sm:w-4/5 w-full',
			'lg': 'xl:w-3/4 md:w-4/5 sm:w-5/5 w-full',
		};

		const options = {
			title,
			customClass: {
				htmlContainer: 'text-blue-primary',
				popup: `md:px-8 px-4 pb-8 pt-12 relative rounded-xl text-blue-primary ${variantSize[size]}`,
				closeButton: 'absolute top-4 right-4 text-white bg-blue-light hover:bg-blue-light',
				confirmButton: 'bg-blue-light hover:bg-blue-dark transition mx-1 my-2 text-white rounded-md px-6 py-3',
				cancelButton: 'bg-grey hover:bg-gray-400 transition mx-1 my-2 text-white rounded-md px-6 py-3',
				title: 'text-blue-dark md:text-2xl text-xl',
			},
			showCloseButton: true,
			showCancelButton,
			buttonsStyling: false,
			allowOutsideClick: () => !Swal.isLoading(),
			showLoaderOnConfirm: true,
			cancelButtonText,
		};

		if (icon) {
			options.icon = icon;
		}

		if (text) {
			options.text = text;
		}

		if (html) {
			options.html = html;
		}

		if (confirmButtonText) {
			options.confirmButtonText = confirmButtonText;
		}

		if (preConfirm) {
			options.preConfirm = preConfirm;
		}

		const MySwal = withReactContent(Swal);
		return MySwal.fire(options);
	};

	return {
		onPopUp,
	};
};

export default usePopUp;
