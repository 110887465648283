import React, {useContext} from 'react';
import {createContext, useState} from 'react';

const Context = createContext();

const ResultProvider = ({children}) => {
	const [activity, setActivity] = useState(null);
	const values = {
		activity,
		setActivity,
	};

	return (
		<Context.Provider value={values}>
			{children}
		</Context.Provider>
	);
};

export const useResultContext = () => useContext(Context);
export default ResultProvider;
