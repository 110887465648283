import {toFixed} from './number';

const currency = (number, currency = 'IDR') => {
	const formatter = new Intl.NumberFormat('id-ID', {
		style: 'currency',
		currency,
		minimumFractionDigits: 0,
	});

	return formatter.format(toFixed(number));
};

export default currency;
