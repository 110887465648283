import React from 'react';
import {createBrowserRouter} from 'react-router-dom';
import HomePage from '../pages/Home';
import IntroducePage from '../pages/Introduce';
import CalculatePage from '../pages/Calculate';
import LoadingPage from '../pages/Loading';
import ResultPage from '../pages/Result';
import PackagePage from '../pages/Package';
import ErrorPage from '../pages/ErrorPage';
import PaymentPage from '../pages/Payment';

const router = createBrowserRouter([
	{
		path: '/',
		element: <HomePage />,
		errorElement: <ErrorPage />,
	},
	{
		path: '/introduce',
		element: <IntroducePage />,
	},
	{
		path: '/calculate',
		element: <CalculatePage />,
	},
	{
		path: '/loading',
		element: <LoadingPage />,
	},
	{
		path: '/result/:token',
		element: <ResultPage />,
	},
	{
		path: '/package/:token',
		element: <PackagePage />,
	},
	{
		path: '/payment/:token',
		element: <PaymentPage />,
	},
]);

export default router;
