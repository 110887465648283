import React, {useContext} from 'react';
import {createContext, useState} from 'react';

const Context = createContext();

const WebSettingProvider = ({children}) => {
	const [webSetting, setWebSetting] = useState(null);

	const values = {
		webSetting,
		setWebSetting,
	};

	return (
		<Context.Provider value={values}>
			{children}
		</Context.Provider>
	);
};

export const useWebSettingContext = () => useContext(Context);
export default WebSettingProvider;
