import axios from 'axios';

const useFetchResult = () => {
	const url = process.env.REACT_APP_API_URL;

	const onFetchResult = async ({
		token,
	}) => {
		const result = await axios.get(`${url}/calculate/${token}`);
		return result;
	};

	return {
		onFetchResult,
	};
};

export default useFetchResult;
