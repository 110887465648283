/* eslint-disable react/no-unescaped-entities */

import React, {useEffect, useState} from 'react';
import Layout from '../../layouts/Layout';
import Input from '../../components/atoms/Input';
import Select from '../../components/atoms/Select';
import Button from '../../components/atoms/Button';
import {LuMoveRight} from 'react-icons/lu';
import {useIntroduceContext} from './Context';
import useFetchCountry from './hooks/useFetchCountry';
import {useNavigate} from 'react-router-dom';

const IntroducePage = () => {
	const {
		country,
		setState,
		state,
		setCity,
		city,
		setValue,
		errors,
		countryLoading,
		stateLoading,
		cityLoading,
		handleSubmit,
		getValues,
	} = useIntroduceContext();

	const [form, setForm] = useState({
		name: getValues('name'),
		phone: getValues('phone'),
		email: getValues('email'),
		country: getValues('country'),
		state: getValues('state'),
		city: getValues('city'),
	});

	const navigate = useNavigate();

	const {onFetchCountry, onFetchState, onFetchCity} = useFetchCountry();

	const onSubmit = () => {
		return navigate('/calculate');
	};

	const onChangeInput = (e) => {
		setForm((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};

	useEffect(() => {
		setValue('name', form.name);
		setValue('phone', form.phone);
		setValue('email', form.email);
		setValue('country', form.country);
		setValue('state', form.state);
		setValue('city', form.city);
	}, [form]);

	return (
		<>
			<Layout>
				<div className="container sm:w-4/6 w-full m-auto">
					<div className="introduce text-center !mb-6">
						<h4 className='text-blue-dark text-xl font-semibold'>
							Hi! Please tell us who you are
						</h4>
						<p className='text-blue-dark text-sm'>
							Don't worry, your information's safe with us!
						</p>
					</div>
					<form onSubmit={handleSubmit(onSubmit)} className='space-y-6'>
						<Input
							name='name'
							type='text'
							label='name'
							placeholder='Enter your name...'
							error={errors?.name?.message}
							value={form.name}
							onChange={onChangeInput}
						/>

						<div className="grid sm:grid-cols-2 grid-cols-1 gap-6">
							<Input
								name='phone'
								type='number'
								label='phone number'
								placeholder='Enter your phone number...'
								error={errors?.phone?.message}
								value={form?.phone}
								onChange={onChangeInput}
								className='appearance-none'
							/>

							<Input
								name='email'
								type='email'
								label='email'
								placeholder='Enter your email...'
								error={errors?.email?.message}
								value={form?.email}
								onChange={onChangeInput}
							/>
						</div>

						<div className="grid sm:grid-cols-3 grid-cols-1 gap-6">
							<Select
								name='country'
								placeholder='Select country'
								label={'Country'}
								value={form?.country}
								onMenuOpen={onFetchCountry}
								isLoading={countryLoading}
								options={country?.map((item) => (
									{
										label: item.name,
										value: item.id,
									}
								))}
								error={errors?.country?.message}
								onChange={(e) => {
									setForm((prev) => ({
										...prev,
										country: e,
										state: null,
										city: null,
									}));

									setState(null);
									setCity(null);
								}}
							/>

							<Select
								name='state'
								placeholder='Select state'
								label={'State/Province'}
								disabled={Boolean(!form?.country) && Boolean(!form?.country)}
								onMenuOpen={() => onFetchState(form.country)}
								isLoading={stateLoading}
								value={form?.state}
								options={state?.map((item) => (
									{
										label: item.name,
										value: item.id,
									}
								))}
								error={errors?.state?.message}
								onChange={(e) => {
									setForm((prev) => ({
										...prev,
										state: e,
										city: null,
									}));

									setCity(null);
								}}
							/>

							<Select
								name='city'
								placeholder='Select city'
								label={'City'}
								isLoading={cityLoading}
								disabled={Boolean(!form?.state) && Boolean(!form?.state)}
								onMenuOpen={() => onFetchCity(form.country, form.state)}
								error={errors?.state?.message}
								value={form?.city}
								onChange={(e) => setForm((prev) => ({
									...prev,
									city: e,
								}))}
								options={city?.map((item) => (
									{
										label: item.name,
										value: item.id,
									}
								))}
							/>
						</div>

						<Button
							type='submit'
							label={'Next'}
							icon={LuMoveRight}
							iconPostition='right'
							className='m-auto !mt-10 px-16 py-2'
						/>
					</form>
					<p className='my-20 text-center text-blue-dark'>
						Lamun Carbon Calculator is a carbon calculator designed
						and developed basis publicly available information and global
						carbon price recommendations and seagrass transplantation
						area requirements determined by TERFs.
					</p>
				</div>
			</Layout>
		</>
	);
};

export default IntroducePage;
