import React from 'react';
import {Link} from 'react-router-dom';

const Brand = () => {
	return (
		<div className="brand">
			<Link to={'/'}>
				<img src="/logo.png" alt="Logo" className='md:w-24 w-20' />
			</Link>
		</div>
	);
};

export default Brand;
