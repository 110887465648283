/* eslint-disable max-len */

import usePopUp from '../../../hooks/usePopUp';

const usePopUpBlueCarbon = () => {
	const {
		onPopUp,
	} = usePopUp();

	const onPopUpBlueCarbon = () => {
		return onPopUp({
			title: 'About Blue Carbon',
			html: `
				<img
					src='/assets/blue-carbon.png'
					class="m-auto my-6 sm:w-1/2 w-full rounded-md"
				/>
				<div class="text-left">
					<p class="leading-normal mb-4 text-blue-dark">
						Blue carbon is the term for <b>carbon captured and carbon
						stored</b> by the world's ocean and coastal ecosystems.
					</p>
					<p class="leading-normal text-blue-dark">
						It has been a long time since everyone in the world knows
						that they emit carbon dioxide gas into the air or into the
						atmosphere and is known as pollution. But, do you know where
						that carbon dioxide goes next? The carbon dioxide is mostly
						absorbed by coastal ecosystems such as Mangroves and Seagrass
						which is now known as Blue Carbon, carbon that is absorbed by
						coastal ecosystems. There are many beaches and coastal areas in
						every corner of the world that play a role in compensating climate
						change due to increased carbon dioxide in the atmosphere by absorbing
						and storing it as blue carbon in Mangroves and Seagrass Meadows.
						So, do you understand why we have to protect and preserve it?
					</p>
				</div>
			`,
		});
	};

	return {
		onPopUpBlueCarbon,
	};
};

export default usePopUpBlueCarbon;
