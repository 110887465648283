export const toFixed = (number, decimal = 2) => {
	if (number % 1 !== 0) {
		return number.toFixed(decimal);
	}

	return number;
};

const separator = (number) => {
	if (isNaN(number)) return number;

	const roundedNumber = String(toFixed(number));
	const [integerPart, fractionalPart] = roundedNumber.split('.');
	const integerWithDots = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
	const formattedNumber = fractionalPart ?
		`${integerWithDots},${fractionalPart}` :
		integerWithDots;

	return formattedNumber;
};

export default separator;
