import axios from 'axios';
import {useParams} from 'react-router-dom';
import {usePackageContext} from '../Context';

const useStorePackage = () => {
	const {token} = useParams();
	const {item, currency} = usePackageContext();

	const url = process.env.REACT_APP_API_URL;

	const onStorePackage = async () => {
		const payload = item?.filter((_item) => {
			return (_item?.qty > 0);
		});
		const result = axios.post(`${url}/donate/${token}`, {
			currency: currency?.value,
			packages: payload?.map((_item) => {
				return {
					package_id: _item?.id,
					qty: _item?.qty,
					price: _item?.price,
				};
			}),
		});
		return result;
	};

	return {
		onStorePackage,
	};
};

export default useStorePackage;
