import axios from 'axios';
import {useIntroduceContext} from '../../Introduce/Context';
import {useResultContext} from '../../Result/Context';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';

const useStoreCalculate = () => {
	const url = process.env.REACT_APP_API_URL;

	const navigate = useNavigate();

	const {
		setActivity,
	} = useResultContext();

	const {
		getValues,
		setValue,
	} = useIntroduceContext();

	const data = getValues();

	const onStoreCalculate = async () => {
		await axios.post(`${url}/calculate`, {
			...data,
			country: data?.country?.value,
			state: data?.state?.value,
			city: data?.city?.value,
		})
			.then((res) => {
				const {data} = res;
				const {result} = data;
				setActivity(result);
				setValue('name', '');
				setValue('email', '');
				setValue('phone', '');
				setValue('country', '');
				setValue('state', '');
				setValue('city', '');
				setValue('carbon', 0);
				return navigate(`/result/${result?.token}`);
			})
			.catch((err) => {
				return toast(err?.message || '500 | Internal Server Error', {
					type: 'error',
				});
			});
	};

	return {
		onStoreCalculate,
	};
};

export default useStoreCalculate;
