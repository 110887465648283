import React from 'react';

const Step = ({label, isActive}) => {
	return (
		<div className={`
            w-10 h-10 rounded-full
            flex
            items-center
            justify-center
            font-bold
            z-10
            text-center
            ${
		isActive ?
			'bg-blue-dark text-gray-200' :
			'bg-gray-200 text-blue-dark'
		}
        `}>{label}</div>
	);
};

export default Step;
