/* eslint-disable max-len */

import React, {useEffect, useState} from 'react';
import Layout from '../../layouts/Layout';
import Subscribe from '../../components/organisms/Subscribe';
import Button from '../../components/atoms/Button';
import PaymentMethodCard from '../../components/molecules/PaymentMethodCard';
import useConfirmPaymentPopUp from './hooks/useConfirmPaymentPopUp';
import {useNavigate, useParams} from 'react-router-dom';
import useFetchResult from '../Result/hooks/useFetchResult';
import {toast} from 'react-toastify';
import {useResultContext} from '../Result/Context';
import separator from '../../utilities/number';
import currency from '../../utilities/currency';
import ErrorPage from '../ErrorPage';

const PaymentPage = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const navigate = useNavigate();

	const {
		onConfirmPaymentPopUp,
	} = useConfirmPaymentPopUp();

	const {
		token,
	} = useParams();

	const {
		activity,
		setActivity,
	} = useResultContext();

	const {
		onFetchResult,
	} = useFetchResult();

	useEffect(() => {
		onFetchResult({
			token,
		})
			.then((res) => {
				const {data} = res;
				const {result} = data;

				if (result?.packages?.length === 0) {
					return navigate('/package/' + token);
				}

				setActivity(result);
			})
			.catch((err) => {
				const {response} = err;
				if (response?.status === 404) {
					return setError({
						status: 404,
					});
				}
				toast(
					response?.data?.message || err?.message || 'Internal Error Server',
					{
						type: 'error',
					},
				);
				return navigate('/');
			})
			.finally(() => {
				return setLoading(false);
			});
	}, []);

	return (
		<>
			{error ? (
				<ErrorPage status={error?.status} />
			) : (
				<Layout step={5}>
					<div className="container xl:w-4/6 w-full m-auto min-h-screen">
						{
							loading ? (
								<div className="w-full rounded h-56 bg-gray-200 animate-pulse" />
							) : (
								<>
									{activity?.is_payment === 1 ? (
										<div className='bg-green-500 text-white rounded p-4 mb-4'>
											Thank you for your donation! Your payment has been received with gratitude.
										</div>
									) : (null)}
									<div className="
										md:grid
										block
										grid-cols-7
										summary
										bg-blue-dark-2
										bg-opacity-5
										p-8
										rounded-lg
										gap-4
									">
										<div className="col-span-4 md:pr-4 md:border-r-4 md:border-gray-300">
											<h4 className="text-blue-dark text-xl font-bold">
												Donation Amount
											</h4>
											<div className="my-4">
												<p className="text-blue-dark-2 font-medium mb-1">
													Carbon Footprint
												</p>
												<h2 className='text-blue-dark-2 font-bold text-3xl'>
													{separator(activity?.carbon)} kgCO²e/m²/year
												</h2>
											</div>
											<div className="flex justify-between">
												<div className="seagrass">
													<p className="text-blue-dark-2 font-medium mb-1">
														Seagrass Need:
													</p>
													<h3 className='text-blue-dark-2 font-bold text-2xl'>
														{separator(activity?.seagress)} m²
													</h3>
												</div>
												<div className="seagrass">
													<p className="text-blue-dark-2 font-medium mb-1">
														Seagrass to be Planted:
													</p>
													<h3 className='text-blue-dark-2 font-bold text-2xl'>
														{
															separator(activity?.packages?.reduce((total, item) => {
																return (total || 0) + (item?.seagrass || 0);
															}, 0))
														} m²
													</h3>
												</div>
											</div>
										</div>
										<div className="col-span-3 md:mt-0 mt-8">
											<div className="bg-blue-dark-2 rounded-xl p-8 text-white">
												<p>Total Donation:</p>
												<h2 className='mt-2 font-bold text-3xl'>
													{
														currency(activity?.packages?.reduce((total, item) => {
															console.log(activity?.currency_rate);
															return (total || 0) + (((item?.price*activity?.currency_rate) || 0)*(item.qty || 0));
														}, 0), activity?.currency)
													}
												</h2>
											</div>
											<Button
												className='w-full mt-4'
												label={'Finish Payment'}
												onClick={onConfirmPaymentPopUp}
												disabled={activity?.is_payment === 1}
											/>
										</div>
									</div>
								</>
							)
						}
						<PaymentMethodCard />
						<Subscribe />
					</div>
				</Layout>
			)}
		</>
	);
};

export default PaymentPage;
