/* eslint-disable max-len */

import React from 'react';
import Button from '../../atoms/Button';
import currencyFormat from '../../../utilities/currency';
import separator from '../../../utilities/number';
import {usePackageContext} from '../../../pages/Package/Context';
import useSumaryPopUp from '../../../pages/Package/hooks/useSumaryPopUp';

const SummaryPackageCard = () => {
	const {
		item,
		currency,
		currencyRate,
	} = usePackageContext();

	const {
		onSumaryPopUp,
	} = useSumaryPopUp();

	const amount = item?.reduce((total, _item) => {
		return (total || 0) + (_item?.amount*currencyRate || 0);
	}, 0);

	return (
		<div className={`
            summary
            bg-blue-dark-2
            bg-opacity-5
			p-8
            rounded-lg
        `}>
			<h4 className={`text-xl text-blue-dark font-semibold leading-loose mb-2`}>
                Donation Package Details
			</h4>
			{
				item?.map((_item, key) => {
					if (_item?.qty > 0) {
						return (
							<p key={key} className={`flex items-center justify-between text-blue-dark-2 font-medium mb-2 leading-normal`}>
								<span className="title text-left">{_item?.name} (x{_item?.qty})</span>
								<span className="amount text-end">{currencyFormat(_item?.amount*currencyRate, currency?.value)}</span>
							</p>
						);
					}
				})
			}
			<div className="py-2 border-t-2 border-grey">
				<p className={`flex items-center justify-between text-blue-dark font-bold`}>
                    Total Donation
					<span>
						{currencyFormat(amount, currency?.value)}
					</span>
				</p>
				<div className="text-center my-2 text-sm text-blue-light">
                    (Equal to {separator(item?.reduce((total, _item) => {
						return (total || 0) + (_item?.total_seagrass || 0);
					}, 0))} m2 Seagrass Meadows)
				</div>
				<Button
					label={'Donor Now'}
					className='w-full mt-8'
					onClick={onSumaryPopUp}
					disabled={amount <= 0}
				/>
			</div>
		</div>
	);
};

export default SummaryPackageCard;
