import React, {useContext, useEffect} from 'react';
import {createContext, useState} from 'react';
import {useWebSettingContext} from '../../../context/WebSettingContext';

const Context = createContext();

const PackageProvider = ({children}) => {
	const {
		webSetting,
	} = useWebSettingContext();

	const [currencyRate, setCurrencyRate] = useState(1);
	const [currency, setCurrency] = useState({
		label: 'Rupiah (Rp)',
		value: 'idr',
	});

	const [packages, setPackages] = useState([]);
	const [item, setItem] = useState([]);

	const onChangeItem = (id, qty) => {
		setItem((prevItem) => {
			const newItem = prevItem.map((_item) => {
				if (_item.id === id) {
					return {
						..._item,
						qty,
						amount: _item?.price * qty,
						total_seagrass: _item?.seagrass * qty,
					};
				}
				return _item;
			});

			return newItem;
		});
	};

	useEffect(() => {
		const _packages = [];
		packages.forEach((item) => {
			_packages.push({
				'id': item?.id,
				'name': item?.title,
				'price': item?.price,
				'amount': 0,
				'qty': 0,
				'seagrass': item?.seagrass,
				'total_seagrass': 0,
			});
		});

		if (item.length === 0) {
			setItem(_packages);
		}
	}, [packages]);

	useEffect(() => {
		const currencyRateSetting =
			webSetting ? JSON.parse(webSetting?.currency) : null;
		const rate = currencyRateSetting ?
			currencyRateSetting?.[currency?.value?.toUpperCase()] : 1;
		setCurrencyRate(rate);
	}, [currency, webSetting]);

	const values = {
		currency,
		setCurrency,
		packages,
		setPackages,
		item,
		setItem,
		onChangeItem,
		currencyRate,
		setCurrencyRate,
	};

	return (
		<Context.Provider value={values}>
			{children}
		</Context.Provider>
	);
};

export const usePackageContext = () => useContext(Context);
export default PackageProvider;
