/* eslint-disable max-len */

import React from 'react';
import {BsInstagram, BsWhatsapp} from 'react-icons/bs';
import {LiaLinkedin} from 'react-icons/lia';
import {MdEmail} from 'react-icons/md';

import {Link} from 'react-router-dom';
import {useWebSettingContext} from '../../../context/WebSettingContext';

const Footer = () => {
	const {
		webSetting,
	} = useWebSettingContext();

	return (
		<div className="bg-gray-lighter py-8 mt-8 text-blue-dark text-center flex sm:gap-16 gap-2 justify-center items-center sm:flex-row flex-col">
			<span className='text-sm font-semibold'>LAMUN CARBON CALCULATOR © 2022 LAMUN WARRIOR</span>
			<span>
				<ul className="nav flex gap-4">
					<li className='text-white bg-blue-dark w-8 h-8 flex items-center justify-center rounded-full'>
						<Link to={webSetting?.instagram || '#'} className='text-white'><BsInstagram fontSize='small' /></Link>
					</li>
					<li className='text-white bg-blue-dark w-8 h-8 flex items-center justify-center rounded-full'>
						<Link to={webSetting?.linkedin || '#'} className='text-white'><LiaLinkedin fontSize='small' /></Link>
					</li>
					<li className='text-white bg-blue-dark w-8 h-8 flex items-center justify-center rounded-full'>
						<Link to={webSetting?.whatsapp || '#'} className='text-white'><BsWhatsapp fontSize='small' /></Link>
					</li>
					<li className='text-white bg-blue-dark w-8 h-8 flex items-center justify-center rounded-full'>
						<Link to={webSetting?.email || '#'} className='text-white'><MdEmail fontSize='small' /></Link>
					</li>
				</ul>
			</span>
		</div>
	);
};

export default Footer;
